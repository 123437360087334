.app-recruit-banner {
  width: 100%;
  height: 200px;
  background-image: url(./img/recruit-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  color: #ffffff;
  font-size: 34px;
  font-weight: 600;
}
.app-recruit-banner-title {
  padding-top: 90px;
  text-align: center;
  width: 100%;
  color: #ffffff;
  font-size: 34px;
  font-weight: 600;
}
.app-recruit-nav {
  width: 920px;
  height: 47px;
  text-align: left;
  line-height: 47px;
  color: #78208e;
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  background-repeat: no-repeat;
  background-size: cover;
}
.app-recruit-nav span {
  padding: 0px 5px 0px 20px;
}
.app-recruit-nav[data-type='cp'] {
  background-image: url(./img/recruit-item-cp.png);
}
.app-recruit-nav[data-type='kf'] {
  background-image: url(./img/recruit-item-kf.png);
}
.app-recruit-nav[data-type='yw'] {
  background-image: url(./img/recruit-item-yw.png);
}
.app-recruit-nav[data-type='sj'] {
  background-image: url(./img/recruit-item-sj.png);
}
.app-recruit-nav[data-type='fw'] {
  background-image: url(./img/recruit-item-fw.png);
}
.app-recruit-flex {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  width: 100%;
}
.app-recruit-content-left {
  text-align: left;
  width: 200px;
  margin-right: 40px;
}
.app-recruit-item {
  width: 100%;
  height: 50px;
  text-align: center;
  color: #2f2f2f;
  font-size: 13px;
  font-family: PingFangSC-Regular;
  background-color: #f7f7f7;
  line-height: 50px;
  margin-bottom: 10px;
}
.app-recruit-item[iscurr='true'] {
  color: #78208e;
}
.app-recruit-content {
  background-color: #f7f7f7;
  text-align: left;
  width: 680px;
  padding: 20px;
  line-height: 31px;
  margin-bottom: 10px;
}
.app-recruit-content-title {
  color: #2f2f2f;
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  line-height: 36px;
}
