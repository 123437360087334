.app-about-banner {
  width: 100%;
  height: 200px;
  background-image: url(./img/about-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.app-about-banner-title {
  color: #ffffff;
  font-size: 34px;
  font-family: PingFangSC-Semibold;
  font-weight: 600;
  height: 200px;
  line-height: 200px;
  width: 100%;
  text-align: center;
  background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
}
.app-about-main {
  margin: 0px;
  padding: 0px;
  background-color: #ffffff;
  width: 100%;
}
.app-about-main-title {
  width: 100%;
  text-align: center;
  font-size: 19px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #2F2F2F;
  padding-top: 40px;
  padding-bottom: 10px;
}
.app-about-main-content {
  margin: 0px;
  padding: 0px;
  background-color: #ffffff;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.app-about-main-dec {
  width: 880px;
  line-height: 24px;
  text-align: left;
}
.app-about-culture {
  margin-top: 40px;
  height: 400px;
  width: 100%;
  background-image: url(./img/qywh-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.app-about-culture-title {
  color: #2f2f2f;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 320px;
  font-size: 16px;
  font-weight: 500;
}
.app-about-culture-item {
  width: 300px;
  height: 240px;
  margin-right: 10px;
  background-color: #ffffff;
}
.app-about-culture-item-img {
  text-align: left;
  margin-top: 50px;
  margin-left: 20px;
}
.app-about-culture-item-title {
  color: #2f2f2f;
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  margin-left: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.app-about-culture-item-dec {
  width: 219px;
  color: #464646;
  font-size: 13px;
  font-family: PingFangSC-Regular;
  text-align: left;
  line-height: 24px;
  margin-left: 20px;
}
