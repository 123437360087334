.app-index-banner {
  width: 100%;
}
.app-index-server {
  width: 100%;
  text-align: center;
  margin-top: 30px;
}
.app-index-server-title {
  height: 48px;
  overflow-wrap: break-word;
  color: #2f2f2f;
  font-size: 34px;
  font-family: PingFangSC-Semibold;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  line-height: 48px;
  display: block;
}
.app-index-server-subtitle {
  overflow-wrap: break-word;
  color: #7b7b7b;
  font-size: 13px;
  font-family: PingFangSC-Semibold;
  text-align: center;
  white-space: nowrap;
  display: block;
  height: 24px;
  line-height: 24px;
}
.app-index-service-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.app-index-service-item {
  width: 300px;
  margin-bottom: 30px;
  text-align: center;
}
.app-index-service-item-title {
  color: #2f2f2f;
  font-size: 20px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  padding: 20px 10px 10px 10px;
}
.app-index-service-item-dec {
  height: 80px;
  overflow-wrap: break-word;
  color: #464646;
  font-size: 13px;
  font-family: PingFangSC-Regular;
  text-align: right;
  padding: 0px 40px;
}
.app-index-service-item-more {
  width: 100%;
  text-align: center;
}
.app-index-service-item-more span {
  display: inline-block;
  width: 100px;
  background-color: #78208e;
  color: #ffffff;
  padding: 2px 0px;
  cursor: pointer;
}
.app-index-solution {
  box-sizing: content-box;
  min-width: 950px;
  margin: 0px;
  padding: 40px 0px 30px 0px;
  width: 100%;
  background-image: url(./img/solution-bg.png);
}
.app-index-solution-title {
  overflow-wrap: break-word;
  color: #2f2f2f;
  font-size: 34px;
  font-family: PingFangSC-Semibold;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  margin: 30px;
  padding: 40px 10px 0px 10px;
}
.app-index-solution-contont {
  width: 100%;
  overflow: hidden;
}
.app-index-solution-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.app-index-solution-item {
  height: 168px;
  width: 300px;
  margin: 0 10px 10px 0;
  display: inline-block;
  position: static;
}
.app-index-solution-item[data-type='jr'] {
  background-image: url(./img/solution-jr.png);
}
.app-index-solution-item[data-type='qc'] {
  background-image: url(./img/solution-qc.png);
}
.app-index-solution-item[data-type='hlw'] {
  background-image: url(./img/solution-hlw.png);
}
.app-index-solution-item[data-type='tx'] {
  background-image: url(./img/solution-tx.png);
}
.app-index-solution-item[data-type='zz'] {
  background-image: url(./img/solution-zz.png);
}
.app-index-solution-item[data-type='fdc'] {
  background-image: url(./img/solution-fdc.png);
}
.app-index-solution-item[data-type='yl'] {
  background-image: url(./img/solution-yl.png);
}
.app-index-solution-item[data-type='jt'] {
  background-image: url(./img/solution-jt.png);
}
.app-index-solution-item[data-type='ny'] {
  background-image: url(./img/solution-ny.png);
}
.app-index-solution-item-title {
  position: relative;
  left: 0px;
  top: 132px;
  background-color: rgba(120, 32, 142, 0.7);
  height: 36px;
  width: 300px;
  color: #ffffff;
  line-height: 36px;
  text-align: center;
}
.app-index-solution-more {
  width: 100%;
  text-align: center;
  padding: 20px 0px;
}
.app-index-solution-more span {
  width: 80px;
  overflow-wrap: break-word;
  color: #78208e;
  font-size: 14px;
  font-family: PingFangSC-Regular;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  width: 82px;
  height: 28px;
  line-height: 28px;
  border: 1px solid #78208e;
  display: inline-block;
}
.app-index-location {
  width: 100%;
  padding-top: 50px;
}
.app-index-location-subtitle {
  color: #7b7b7b;
  font-size: 13px;
  font-family: PingFangSC-Semibold;
  width: 100%;
  margin-top: 10px;
  text-align: center;
}
.app-index-location-subtitle span {
  display: inline-block;
  width: 900px;
  text-align: right;
}
.app-index-location-map {
  margin-bottom: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.app-index-location-map-china {
  margin-top: 40px;
  margin-bottom: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.app-index-location-map-left {
  background-image: url(./img/map-left.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 495px;
  width: 124px;
  text-align: center;
  line-height: 495px;
}
.app-index-location-map-left span {
  display: block;
  width: 124px;
  height: 48px;
  text-align: center;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 20px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
}
.app-index-location-map-left img {
  cursor: pointer;
}
.app-index-location-map-content {
  background-image: url(./img/map-world.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 495px;
  width: 796px;
}
.app-index-map-location-outer {
  background-color: rgba(120, 32, 142, 0.18);
  border-radius: 50%;
  height: 22px;
  width: 22px;
}
.app-index-map-location-inner {
  background-color: #78208e;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  position: relative;
  left: 5px;
  top: 5px;
}
.app-index-map-location-city {
  position: relative;
  text-align: center;
  width: 60px;
}
.app-index-map-location-city-name {
  width: 60px;
  height: 28px;
  color: #78208e;
  font-size: 20px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: center;
}
.app-index-map-location-city-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}
.app-index-cst-bg {
  background-color: #f0f3ff;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 60px;
}
.app-index-cst-totle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
}
.app-index-cst-totle-item {
  width: 220px;
  background-color: #ffffff;
  height: 80px;
  text-align: center;
}
.app-index-cst-totle-item-a {
  color: #78208e;
  font-size: 34px;
  font-family: Impact;
  padding-top: 10px;
}
.app-index-cst-totle-item-blank {
  height: 80px;
  width: 20px;
  background-color: #ffffff;
}
.app-index-cst-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}
.app-index-cst-content-item {
  background-color: #ffffff;
  height: 80px;
  width: 222px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-right: 10px;
}
.app-index-cst-evaluate {
  width: 456px;
  height: 134px;
  background-color: #ffffff;
  background-image: url("./img/douhao.png");
  background-repeat: no-repeat;
  background-position-x: 10px;
  background-position-y: 10px;
}
.app-index-cst-evaluate-dec {
  width: 367px;
  overflow-wrap: break-word;
  color: #2f2f2f;
  font-size: 13px;
  font-family: PingFangSC-Regular;
  text-align: left;
  line-height: 24px;
  margin-top: 28px;
  margin-left: 40px;
}
.app-index-cst-evaluate-cst {
  width: 367px;
  color: #2f2f2f;
  font-size: 13px;
  font-family: PingFangSC-Regular;
  text-align: right;
  line-height: 24px;
  margin-left: 40px;
}
