.app-location-banner {
  width: 100%;
  height: 200px;
  background-image: url(./img/location-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.app-location-banner-title {
  padding-top: 90px;
  text-align: center;
  width: 100%;
  color: #ffffff;
  font-size: 34px;
  font-weight: 600;
}
.app-location-title {
  color: #2f2f2f;
  font-size: 34px;
  font-family: PingFangSC-Semibold;
  font-weight: 600;
  text-align: center;
}
.app-location-dec {
  overflow-wrap: break-word;
  color: #2f2f2f;
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  width: 400px;
  line-height: 32px;
}
.app-laction-wx-outer {
  padding: 10px 20px;
}
.app-laction-wx {
  background-color: rgba(216, 216, 216, 0.2);
  width: 157px;
  height: 157px;
}
.app-laction-wx-down {
  width: 157px;
  text-align: center;
}
.app-location-map-bg {
  margin-top: 50px;
  width: 100%;
  min-width: 950px;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(./img/location-map.png);
}
.app-location-map-location {
  width: 100%;
  height: auto;
}
