.page {
  background-color: #ffffff;
  position: relative;
  width: 1920px;
  height: 3399px;
  overflow: hidden;
}
.box_1 {
  background-image: url(./img/1924bc788ad244c592ad68a045567588_mergeImage.png);
  width: 1920px;
  height: 300px;
}
.group_1 {
  background-color: rgba(255, 255, 255, 0.9);
  width: 1920px;
  height: 70px;
}
.image_1 {
  width: 118px;
  height: 40px;
  margin: 15px 0 0 60px;
}
.text_1 {
  width: 32px;
  height: 18px;
  overflow-wrap: break-word;
  color: #464646;
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: justify;
  white-space: nowrap;
  line-height: 18px;
  margin: 26px 0 0 439px;
}
.text_2 {
  width: 64px;
  height: 18px;
  overflow-wrap: break-word;
  color: #2f2f2f;
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: justify;
  white-space: nowrap;
  line-height: 18px;
  margin: 26px 0 0 44px;
}
.text_3 {
  width: 64px;
  height: 18px;
  overflow-wrap: break-word;
  color: #464646;
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: justify;
  white-space: nowrap;
  line-height: 18px;
  margin: 26px 0 0 44px;
}
.text_4 {
  width: 64px;
  height: 18px;
  overflow-wrap: break-word;
  color: #78208e;
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: justify;
  white-space: nowrap;
  line-height: 18px;
  margin: 26px 0 0 44px;
}
.text_5 {
  width: 64px;
  height: 18px;
  overflow-wrap: break-word;
  color: #464646;
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: justify;
  white-space: nowrap;
  line-height: 18px;
  margin: 26px 0 0 44px;
}
.text_6 {
  width: 64px;
  height: 18px;
  overflow-wrap: break-word;
  color: #464646;
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: justify;
  white-space: nowrap;
  line-height: 18px;
  margin: 26px 0 0 44px;
}
.text_7 {
  width: 70px;
  height: 18px;
  overflow-wrap: break-word;
  color: #464646;
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: justify;
  white-space: nowrap;
  line-height: 18px;
  margin: 26px 0 0 44px;
}
.text_8 {
  width: 24px;
  height: 18px;
  overflow-wrap: break-word;
  color: #464646;
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: justify;
  white-space: nowrap;
  line-height: 18px;
  margin: 26px 0 0 343px;
}
.text_9 {
  width: 20px;
  height: 18px;
  overflow-wrap: break-word;
  color: #464646;
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: justify;
  white-space: nowrap;
  line-height: 18px;
  margin-top: 26px;
}
.label_1 {
  width: 23px;
  height: 22px;
  margin: 24px 0 0 40px;
}
.text_10 {
  width: 116px;
  height: 18px;
  overflow-wrap: break-word;
  color: #464646;
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: justify;
  white-space: nowrap;
  line-height: 18px;
  margin: 26px 40px 0 11px;
}
.paragraph_1 {
  width: 408px;
  height: 96px;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 34px;
  font-family: PingFangSC-Semibold;
  font-weight: 600;
  text-align: right;
  line-height: 48px;
  margin: 40px 0 0 756px;
}
.group_2 {
  background-color: #ffffff;
  height: 51px;
  width: 920px;
  margin: 43px 0 0 500px;
}
.text-wrapper_1 {
  width: 746px;
  height: 22px;
  margin: 15px 0 0 87px;
}
.text_11 {
  width: 32px;
  height: 22px;
  overflow-wrap: break-word;
  color: #78208e;
  font-size: 16px;
  font-family: PingFangSC-Semibold;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  line-height: 22px;
}
.text_12 {
  width: 48px;
  height: 22px;
  overflow-wrap: break-word;
  color: #2f2f2f;
  font-size: 16px;
  font-family: PingFangSC-Semibold;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  line-height: 22px;
  margin-left: 80px;
}
.text_13 {
  width: 32px;
  height: 22px;
  overflow-wrap: break-word;
  color: #2f2f2f;
  font-size: 16px;
  font-family: PingFangSC-Semibold;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  line-height: 22px;
  margin-left: 80px;
}
.text_14 {
  width: 32px;
  height: 22px;
  overflow-wrap: break-word;
  color: #2f2f2f;
  font-size: 16px;
  font-family: PingFangSC-Semibold;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  line-height: 22px;
  margin-left: 88px;
}
.text_15 {
  width: 32px;
  height: 22px;
  overflow-wrap: break-word;
  color: #2f2f2f;
  font-size: 16px;
  font-family: PingFangSC-Semibold;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  line-height: 22px;
  margin-left: 82px;
}
.text_16 {
  width: 32px;
  height: 22px;
  overflow-wrap: break-word;
  color: #2f2f2f;
  font-size: 16px;
  font-family: PingFangSC-Semibold;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  line-height: 22px;
  margin-left: 88px;
}
.text_17 {
  width: 32px;
  height: 22px;
  overflow-wrap: break-word;
  color: #2f2f2f;
  font-size: 16px;
  font-family: PingFangSC-Semibold;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  line-height: 22px;
  margin-left: 88px;
}
.block_1 {
  width: 120px;
  height: 3px;
  margin: 11px 0 0 43px;
}
.section_1 {
  background-color: #78208e;
  width: 120px;
  height: 3px;
}
.box_2 {
  width: 1920px;
  height: 3004px;
}
.image_2 {
  width: 920px;
  height: 1px;
  margin-left: 500px;
}
.box_3 {
  width: 154px;
  height: 22px;
  margin: 31px 0 0 524px;
}
.thumbnail_1 {
  width: 16px;
  height: 16px;
  margin-top: 3px;
}
.text_18 {
  width: 128px;
  height: 22px;
  overflow-wrap: break-word;
  color: #2f2f2f;
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;
}
.box_4 {
  width: 922px;
  height: 271px;
  margin: 30px 0 0 500px;
}
.group_3 {
  background-color: #f7f7f7;
  width: 300px;
  height: 271px;
}
.image-text_1 {
  width: 300px;
  height: 254px;
}
.box_5 {
  background-image: url(./img/5ecc88b3b2e94e4d8d44e8291aa1d89d_mergeImage.png);
  height: 191px;
  width: 300px;
}
.group_4 {
  background-color: #78208e;
  width: 300px;
  height: 36px;
  margin-top: 155px;
}
.text_19 {
  width: 32px;
  height: 22px;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;
  margin: 6px 0 0 20px;
}
.text-wrapper_2 {
  border-radius: 2px;
  height: 15px;
  border: 1px solid #fe5100;
  width: 28px;
  margin: 11px 0 0 10px;
}
.text_20 {
  width: 20px;
  height: 14px;
  overflow-wrap: break-word;
  color: #fe5100;
  font-size: 10px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  line-height: 14px;
  margin-left: 4px;
}
.section_2 {
  position: relative;
  width: 94px;
  height: 24px;
  border: 1px solid #ffffff;
  margin: 6px 10px 0 106px;
}
.image-text_2 {
  width: 74px;
  height: 18px;
  margin: 3px 0 0 10px;
}
.text-group_1 {
  width: 52px;
  height: 18px;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 13px;
  font-family: PingFangSC-Regular;
  font-weight: NaN;
  text-align: center;
  white-space: nowrap;
  line-height: 18px;
}
.thumbnail_2 {
  width: 16px;
  height: 16px;
  margin-top: 1px;
}
.image-text_3 {
  position: absolute;
  left: 10px;
  top: 3px;
  width: 74px;
  height: 18px;
}
.text-group_2 {
  width: 260px;
  height: 48px;
  overflow-wrap: break-word;
  color: #464646;
  font-size: 13px;
  font-family: PingFangSC-Regular;
  font-weight: NaN;
  text-align: left;
  line-height: 24px;
  margin: 15px 0 0 20px;
}
.group_5 {
  background-color: #f7f7f7;
  width: 300px;
  height: 271px;
  margin-left: 11px;
}
.image-text_4 {
  width: 300px;
  height: 254px;
}
.section_3 {
  background-image: url(./img/d11ccd1abb0f42e18c55be8005d22aab_mergeImage.png);
  height: 191px;
  width: 300px;
}
.box_6 {
  background-color: #78208e;
  width: 300px;
  height: 36px;
  margin-top: 155px;
}
.text_21 {
  width: 32px;
  height: 22px;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;
  margin: 6px 0 0 20px;
}
.text-wrapper_3 {
  border-radius: 2px;
  height: 15px;
  border: 1px solid #fe5100;
  width: 28px;
  margin: 11px 0 0 10px;
}
.text_22 {
  width: 20px;
  height: 14px;
  overflow-wrap: break-word;
  color: #fe5100;
  font-size: 10px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  line-height: 14px;
  margin-left: 4px;
}
.box_7 {
  position: relative;
  width: 94px;
  height: 24px;
  border: 1px solid #ffffff;
  margin: 6px 10px 0 106px;
}
.image-text_5 {
  width: 74px;
  height: 18px;
  margin: 3px 0 0 10px;
}
.text-group_3 {
  width: 52px;
  height: 18px;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 13px;
  font-family: PingFangSC-Regular;
  font-weight: NaN;
  text-align: center;
  white-space: nowrap;
  line-height: 18px;
}
.thumbnail_3 {
  width: 16px;
  height: 16px;
  margin-top: 1px;
}
.image-text_6 {
  position: absolute;
  left: 10px;
  top: 3px;
  width: 74px;
  height: 18px;
}
.text-group_4 {
  width: 260px;
  height: 48px;
  overflow-wrap: break-word;
  color: #464646;
  font-size: 13px;
  font-family: PingFangSC-Regular;
  font-weight: NaN;
  text-align: left;
  line-height: 24px;
  margin: 15px 0 0 20px;
}
.group_6 {
  background-color: #f7f7f7;
  width: 300px;
  height: 271px;
  margin-left: 11px;
}
.image-text_7 {
  width: 300px;
  height: 254px;
}
.box_8 {
  background-image: url(./img/40f6d02ad2254d149573e082dfb6133a_mergeImage.png);
  height: 191px;
  width: 300px;
}
.block_2 {
  background-color: #78208e;
  width: 300px;
  height: 36px;
  margin-top: 155px;
}
.text_23 {
  width: 32px;
  height: 22px;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;
  margin: 6px 0 0 20px;
}
.text-wrapper_4 {
  border-radius: 2px;
  height: 15px;
  border: 1px solid #fe5100;
  width: 28px;
  margin: 11px 0 0 10px;
}
.text_24 {
  width: 20px;
  height: 14px;
  overflow-wrap: break-word;
  color: #fe5100;
  font-size: 10px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  line-height: 14px;
  margin-left: 4px;
}
.group_7 {
  position: relative;
  width: 94px;
  height: 24px;
  border: 1px solid #ffffff;
  margin: 6px 10px 0 106px;
}
.image-text_8 {
  width: 74px;
  height: 18px;
  margin: 3px 0 0 10px;
}
.text-group_5 {
  width: 52px;
  height: 18px;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 13px;
  font-family: PingFangSC-Regular;
  font-weight: NaN;
  text-align: center;
  white-space: nowrap;
  line-height: 18px;
}
.thumbnail_4 {
  width: 16px;
  height: 16px;
  margin-top: 1px;
}
.image-text_9 {
  position: absolute;
  left: 10px;
  top: 3px;
  width: 74px;
  height: 18px;
}
.text-group_6 {
  width: 260px;
  height: 48px;
  overflow-wrap: break-word;
  color: #464646;
  font-size: 13px;
  font-family: PingFangSC-Regular;
  font-weight: NaN;
  text-align: left;
  line-height: 24px;
  margin: 15px 0 0 20px;
}
.text_25 {
  width: 272px;
  height: 48px;
  overflow-wrap: break-word;
  color: #2f2f2f;
  font-size: 34px;
  font-family: PingFangSC-Semibold;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  line-height: 48px;
  margin: 32px 0 0 824px;
}
.image_3 {
  width: 31px;
  height: 15px;
  margin: 5px 0 0 945px;
}
.box_9 {
  background-image: url(./img/2352c3d42b6147f2abd6d6a2f565197f_mergeImage.png);
  width: 920px;
  height: 47px;
  margin: 14px 0 0 500px;
}
.image-text_10 {
  width: 58px;
  height: 22px;
  margin: 12px 0 0 24px;
}
.thumbnail_5 {
  width: 16px;
  height: 16px;
  margin-top: 3px;
}
.text-group_7 {
  width: 32px;
  height: 22px;
  overflow-wrap: break-word;
  color: #78208e;
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  line-height: 22px;
}
.block_3 {
  width: 281px;
  height: 47px;
  background: url(./img/SketchPng7547143a14156db94ba5a42634ea6b3b112de9a6413a0bb23e9defa32444e262.png) 100% no-repeat;
  background-size: 100% 100%;
  margin: 0 218px 0 339px;
}
.box_10 {
  width: 920px;
  height: 123px;
  margin: 10px 0 0 500px;
}
.group_8 {
  background-color: #f7f7f7;
  width: 300px;
  height: 123px;
}
.group_9 {
  width: 270px;
  height: 28px;
  margin: 20px 0 0 10px;
}
.image-text_11 {
  width: 88px;
  height: 28px;
}
.label_2 {
  width: 28px;
  height: 28px;
}
.text-group_8 {
  width: 48px;
  height: 22px;
  overflow-wrap: break-word;
  color: #2f2f2f;
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  line-height: 22px;
  margin-top: 3px;
}
.text-wrapper_5 {
  border-radius: 2px;
  height: 15px;
  border: 1px solid #fe5100;
  width: 28px;
  margin: 7px 0 0 10px;
}
.text_26 {
  width: 20px;
  height: 14px;
  overflow-wrap: break-word;
  color: #fe5100;
  font-size: 10px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  line-height: 14px;
  margin-left: 4px;
}
.thumbnail_6 {
  width: 16px;
  height: 16px;
  margin: 6px 0 0 128px;
}
.text_27 {
  width: 219px;
  height: 48px;
  overflow-wrap: break-word;
  color: #464646;
  font-size: 13px;
  font-family: PingFangSC-Regular;
  font-weight: NaN;
  text-align: left;
  line-height: 24px;
  margin: 4px 0 23px 50px;
}
.group_10 {
  background-color: #f7f7f7;
  height: 123px;
  margin-left: 10px;
  width: 300px;
}
.box_11 {
  width: 270px;
  height: 28px;
  margin: 20px 0 0 10px;
}
.image-text_12 {
  width: 72px;
  height: 28px;
}
.label_3 {
  width: 28px;
  height: 28px;
}
.text-group_9 {
  width: 32px;
  height: 22px;
  overflow-wrap: break-word;
  color: #2f2f2f;
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  line-height: 22px;
  margin-top: 3px;
}
.text-wrapper_6 {
  border-radius: 2px;
  height: 15px;
  border: 1px solid #fe5100;
  width: 28px;
  margin: 7px 0 0 9px;
}
.text_28 {
  width: 20px;
  height: 14px;
  overflow-wrap: break-word;
  color: #fe5100;
  font-size: 10px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  line-height: 14px;
  margin-left: 4px;
}
.thumbnail_7 {
  width: 16px;
  height: 16px;
  margin: 6px 0 0 145px;
}
.text-wrapper_7 {
  width: 208px;
  height: 24px;
  margin: 4px 0 47px 50px;
}
.text_29 {
  width: 208px;
  height: 24px;
  overflow-wrap: break-word;
  color: #464646;
  font-size: 13px;
  font-family: PingFangSC-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 24px;
}
.group_11 {
  background-color: #f7f7f7;
  width: 300px;
  height: 123px;
  margin-left: 10px;
}
.group_12 {
  width: 270px;
  height: 28px;
  margin: 20px 0 0 10px;
}
.image-text_13 {
  width: 72px;
  height: 28px;
}
.label_4 {
  width: 28px;
  height: 28px;
}
.text-group_10 {
  width: 32px;
  height: 22px;
  overflow-wrap: break-word;
  color: #2f2f2f;
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  line-height: 22px;
  margin-top: 3px;
}
.text-wrapper_8 {
  border-radius: 2px;
  height: 15px;
  border: 1px solid #fe5100;
  width: 28px;
  margin: 7px 0 0 10px;
}
.text_30 {
  width: 20px;
  height: 14px;
  overflow-wrap: break-word;
  color: #fe5100;
  font-size: 10px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  line-height: 14px;
  margin-left: 4px;
}
.thumbnail_8 {
  width: 16px;
  height: 16px;
  margin: 6px 0 0 144px;
}
.paragraph_2 {
  width: 219px;
  height: 53px;
  overflow-wrap: break-word;
  color: #464646;
  font-size: 13px;
  font-family: PingFangSC-Regular;
  font-weight: NaN;
  text-align: left;
  line-height: 24px;
  margin: 4px 0 18px 50px;
}
.box_12 {
  width: 1274px;
  height: 148px;
  margin: 14px 0 0 500px;
}
.section_4 {
  background-color: #f7f7f7;
  width: 300px;
  height: 123px;
}
.box_13 {
  width: 270px;
  height: 28px;
  margin: 20px 0 0 10px;
}
.image-text_14 {
  width: 72px;
  height: 28px;
}
.label_5 {
  width: 28px;
  height: 28px;
}
.text-group_11 {
  width: 32px;
  height: 22px;
  overflow-wrap: break-word;
  color: #2f2f2f;
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  line-height: 22px;
  margin-top: 3px;
}
.text-wrapper_9 {
  border-radius: 2px;
  height: 15px;
  border: 1px solid #fe5100;
  width: 28px;
  margin: 7px 0 0 10px;
}
.text_31 {
  width: 20px;
  height: 14px;
  overflow-wrap: break-word;
  color: #fe5100;
  font-size: 10px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  line-height: 14px;
  margin-left: 4px;
}
.thumbnail_9 {
  width: 16px;
  height: 16px;
  margin: 6px 0 0 144px;
}
.text_32 {
  width: 219px;
  height: 48px;
  overflow-wrap: break-word;
  color: #464646;
  font-size: 13px;
  font-family: PingFangSC-Regular;
  font-weight: NaN;
  text-align: left;
  line-height: 24px;
  margin: 4px 0 23px 50px;
}
.section_5 {
  background-color: #f7f7f7;
  width: 300px;
  height: 123px;
  margin-left: 10px;
}
.image-text_15 {
  width: 72px;
  height: 28px;
  margin: 20px 0 0 10px;
}
.label_6 {
  width: 28px;
  height: 28px;
}
.text-group_12 {
  width: 32px;
  height: 22px;
  overflow-wrap: break-word;
  color: #2f2f2f;
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  line-height: 22px;
  margin-top: 3px;
}
.text_33 {
  width: 208px;
  height: 24px;
  overflow-wrap: break-word;
  color: #464646;
  font-size: 13px;
  font-family: PingFangSC-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 24px;
  margin: 4px 0 47px 50px;
}
.image-wrapper_1 {
  background-color: #ffffff;
  border-radius: 2px;
  height: 36px;
  border: 1px solid #78208e;
  width: 36px;
  margin: 112px 0 0 628px;
}
.label_7 {
  width: 23px;
  height: 22px;
  margin: 7px 0 0 7px;
}
.box_14 {
  width: 1274px;
  height: 181px;
  margin: 4px 0 0 500px;
}
.block_4 {
  width: 920px;
  height: 180px;
  margin-top: 1px;
}
.box_15 {
  background-image: url(./img/1de5d378661e47f3938d426b753be181_mergeImage.png);
  width: 920px;
  height: 47px;
}
.image-text_16 {
  width: 73px;
  height: 22px;
  margin: 12px 0 0 24px;
}
.thumbnail_10 {
  width: 16px;
  height: 16px;
  margin-top: 3px;
}
.text-group_13 {
  width: 48px;
  height: 22px;
  overflow-wrap: break-word;
  color: #78208e;
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  line-height: 22px;
}
.section_6 {
  width: 281px;
  height: 47px;
  background: url(./img/SketchPng7547143a14156db94ba5a42634ea6b3b112de9a6413a0bb23e9defa32444e262.png) 100% no-repeat;
  background-size: 100% 100%;
  margin: 0 159px 0 383px;
}
.box_16 {
  width: 920px;
  height: 123px;
  margin-top: 10px;
}
.group_13 {
  background-color: #f7f7f7;
  height: 123px;
  width: 300px;
}
.box_17 {
  width: 270px;
  height: 28px;
  margin: 20px 0 0 10px;
}
.image-text_17 {
  width: 88px;
  height: 28px;
}
.label_8 {
  width: 28px;
  height: 28px;
}
.text-group_14 {
  width: 48px;
  height: 22px;
  overflow-wrap: break-word;
  color: #2f2f2f;
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  line-height: 22px;
  margin-top: 3px;
}
.text-wrapper_10 {
  border-radius: 2px;
  height: 15px;
  border: 1px solid #fe5100;
  width: 28px;
  margin: 7px 0 0 10px;
}
.text_34 {
  width: 20px;
  height: 14px;
  overflow-wrap: break-word;
  color: #fe5100;
  font-size: 10px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  line-height: 14px;
  margin-left: 4px;
}
.thumbnail_11 {
  width: 16px;
  height: 16px;
  margin: 6px 0 0 128px;
}
.text-wrapper_11 {
  width: 195px;
  height: 24px;
  margin: 4px 0 47px 50px;
}
.text_35 {
  width: 195px;
  height: 24px;
  overflow-wrap: break-word;
  color: #464646;
  font-size: 13px;
  font-family: PingFangSC-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 24px;
}
.group_14 {
  background-color: #f7f7f7;
  width: 300px;
  height: 123px;
  margin-left: 10px;
}
.image-text_18 {
  width: 248px;
  height: 56px;
  margin: 20px 0 0 10px;
}
.label_9 {
  width: 28px;
  height: 28px;
}
.text-group_15 {
  width: 208px;
  height: 53px;
  margin-top: 3px;
}
.text_36 {
  width: 64px;
  height: 22px;
  overflow-wrap: break-word;
  color: #2f2f2f;
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  line-height: 22px;
}
.text_37 {
  width: 208px;
  height: 24px;
  overflow-wrap: break-word;
  color: #464646;
  font-size: 13px;
  font-family: PingFangSC-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 24px;
  margin-top: 7px;
}
.group_15 {
  background-color: #f7f7f7;
  width: 300px;
  height: 123px;
  margin-left: 10px;
}
.image-text_19 {
  width: 259px;
  height: 85px;
  margin: 20px 0 0 10px;
}
.label_10 {
  width: 28px;
  height: 28px;
}
.text-group_16 {
  width: 219px;
  height: 82px;
  margin-top: 3px;
}
.text_38 {
  width: 64px;
  height: 22px;
  overflow-wrap: break-word;
  color: #2f2f2f;
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  line-height: 22px;
}
.paragraph_3 {
  width: 219px;
  height: 53px;
  overflow-wrap: break-word;
  color: #464646;
  font-size: 13px;
  font-family: PingFangSC-Regular;
  font-weight: NaN;
  text-align: left;
  line-height: 24px;
  margin-top: 7px;
}
.block_5 {
  width: 36px;
  height: 77px;
}
.image-wrapper_2 {
  background-color: #ffffff;
  border-radius: 2px;
  height: 36px;
  border: 1px solid #78208e;
  width: 36px;
}
.label_11 {
  width: 21px;
  height: 21px;
  margin: 8px 0 0 8px;
}
.group_16 {
  background-color: #ffffff;
  border-radius: 2px;
  height: 36px;
  border: 1px solid #78208e;
  margin-top: 5px;
  width: 36px;
}
.image-wrapper_3 {
  height: 11px;
  background: url(./img/SketchPng9a9701fd1564560c9db40f46734f74a4f11b7a656b40d0c1ef226c79c56ec53a.png) -2px -3px no-repeat;
  background-size: 26px 16px;
  width: 23px;
  margin: 8px 0 0 7px;
}
.image_4 {
  width: 1px;
  height: 21px;
  margin: 1px 0 0 10px;
}
.box_18 {
  width: 920px;
  height: 123px;
  margin: 14px 0 0 500px;
}
.block_6 {
  background-color: #f7f7f7;
  width: 300px;
  height: 123px;
}
.image-text_20 {
  width: 235px;
  height: 56px;
  margin: 20px 0 0 10px;
}
.label_12 {
  width: 28px;
  height: 28px;
}
.text-group_17 {
  width: 195px;
  height: 53px;
  margin-top: 3px;
}
.text_39 {
  width: 64px;
  height: 22px;
  overflow-wrap: break-word;
  color: #2f2f2f;
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;
}
.text_40 {
  width: 195px;
  height: 24px;
  overflow-wrap: break-word;
  color: #464646;
  font-size: 13px;
  font-family: PingFangSC-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 24px;
  margin-top: 7px;
}
.block_7 {
  background-color: #f7f7f7;
  width: 300px;
  height: 123px;
  margin-left: 10px;
}
.image-text_21 {
  width: 248px;
  height: 56px;
  margin: 20px 0 0 10px;
}
.label_13 {
  width: 28px;
  height: 28px;
}
.text-group_18 {
  width: 208px;
  height: 53px;
  margin-top: 3px;
}
.text_41 {
  width: 64px;
  height: 22px;
  overflow-wrap: break-word;
  color: #2f2f2f;
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  line-height: 22px;
}
.text_42 {
  width: 208px;
  height: 24px;
  overflow-wrap: break-word;
  color: #464646;
  font-size: 13px;
  font-family: PingFangSC-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 24px;
  margin-top: 7px;
}
.block_8 {
  background-color: #f7f7f7;
  width: 300px;
  height: 123px;
  margin-left: 10px;
}
.image-text_22 {
  width: 259px;
  height: 85px;
  margin: 20px 0 0 10px;
}
.label_14 {
  width: 28px;
  height: 28px;
}
.text-group_19 {
  width: 219px;
  height: 82px;
  margin-top: 3px;
}
.text_43 {
  width: 64px;
  height: 22px;
  overflow-wrap: break-word;
  color: #2f2f2f;
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  line-height: 22px;
}
.paragraph_4 {
  width: 219px;
  height: 53px;
  overflow-wrap: break-word;
  color: #464646;
  font-size: 13px;
  font-family: PingFangSC-Regular;
  font-weight: NaN;
  text-align: left;
  line-height: 24px;
  margin-top: 7px;
}
.box_19 {
  width: 920px;
  height: 123px;
  margin: 16px 0 0 500px;
}
.box_20 {
  background-color: #f7f7f7;
  width: 300px;
  height: 123px;
}
.image-text_23 {
  width: 235px;
  height: 56px;
  margin: 20px 0 0 10px;
}
.label_15 {
  width: 28px;
  height: 28px;
}
.text-group_20 {
  width: 195px;
  height: 53px;
  margin-top: 3px;
}
.text_44 {
  width: 64px;
  height: 22px;
  overflow-wrap: break-word;
  color: #2f2f2f;
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;
}
.text_45 {
  width: 195px;
  height: 24px;
  overflow-wrap: break-word;
  color: #464646;
  font-size: 13px;
  font-family: PingFangSC-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 24px;
  margin-top: 7px;
}
.box_21 {
  background-color: #f7f7f7;
  width: 300px;
  height: 123px;
  margin-left: 10px;
}
.image-text_24 {
  width: 248px;
  height: 56px;
  margin: 20px 0 0 10px;
}
.label_16 {
  width: 28px;
  height: 28px;
}
.text-group_21 {
  width: 208px;
  height: 53px;
  margin-top: 3px;
}
.text_46 {
  width: 64px;
  height: 22px;
  overflow-wrap: break-word;
  color: #2f2f2f;
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;
}
.text_47 {
  width: 208px;
  height: 24px;
  overflow-wrap: break-word;
  color: #464646;
  font-size: 13px;
  font-family: PingFangSC-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 24px;
  margin-top: 7px;
}
.box_22 {
  background-color: #f7f7f7;
  width: 300px;
  height: 123px;
  margin-left: 10px;
}
.section_7 {
  width: 142px;
  height: 28px;
  margin: 20px 0 0 10px;
}
.image-text_25 {
  width: 104px;
  height: 28px;
}
.label_17 {
  width: 28px;
  height: 28px;
}
.text-group_22 {
  width: 64px;
  height: 22px;
  overflow-wrap: break-word;
  color: #2f2f2f;
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;
  margin-top: 3px;
}
.text-wrapper_12 {
  border-radius: 2px;
  height: 15px;
  border: 1px solid #fe5100;
  margin-top: 7px;
  width: 28px;
}
.text_48 {
  width: 20px;
  height: 14px;
  overflow-wrap: break-word;
  color: #fe5100;
  font-size: 10px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  line-height: 14px;
  margin-left: 4px;
}
.paragraph_5 {
  width: 219px;
  height: 53px;
  overflow-wrap: break-word;
  color: #464646;
  font-size: 13px;
  font-family: PingFangSC-Regular;
  font-weight: NaN;
  text-align: left;
  line-height: 24px;
  margin: 4px 0 18px 50px;
}
.box_23 {
  background-image: url(./img/66b85262dfe34c938c516db285755089_mergeImage.png);
  width: 920px;
  height: 47px;
  margin: 30px 0 0 500px;
}
.image-text_26 {
  width: 58px;
  height: 22px;
  margin: 12px 0 0 24px;
}
.thumbnail_12 {
  width: 16px;
  height: 16px;
  margin-top: 3px;
}
.text-group_23 {
  width: 32px;
  height: 22px;
  overflow-wrap: break-word;
  color: #78208e;
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;
}
.group_17 {
  width: 281px;
  height: 47px;
  background: url(./img/SketchPng7547143a14156db94ba5a42634ea6b3b112de9a6413a0bb23e9defa32444e262.png) 100% no-repeat;
  background-size: 100% 100%;
  margin: 0 159px 0 398px;
}
.box_24 {
  background-color: #f7f7f7;
  width: 300px;
  height: 123px;
  margin: 10px 0 0 500px;
}
.section_8 {
  width: 270px;
  height: 28px;
  margin: 20px 0 0 10px;
}
.image-text_27 {
  width: 118px;
  height: 28px;
}
.label_18 {
  width: 28px;
  height: 28px;
}
.text-group_24 {
  width: 78px;
  height: 22px;
  overflow-wrap: break-word;
  color: #2f2f2f;
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;
  margin-top: 3px;
}
.text-wrapper_13 {
  border-radius: 2px;
  height: 15px;
  border: 1px solid #fe5100;
  width: 28px;
  margin: 7px 0 0 10px;
}
.text_49 {
  width: 20px;
  height: 14px;
  overflow-wrap: break-word;
  color: #fe5100;
  font-size: 10px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  line-height: 14px;
  margin-left: 4px;
}
.thumbnail_13 {
  width: 16px;
  height: 16px;
  margin: 6px 0 0 98px;
}
.paragraph_6 {
  width: 219px;
  height: 53px;
  overflow-wrap: break-word;
  color: #464646;
  font-size: 13px;
  font-family: PingFangSC-Regular;
  font-weight: NaN;
  text-align: left;
  line-height: 24px;
  margin: 4px 0 18px 50px;
}
.box_25 {
  background-image: url(./img/db2ef9015f80472cb5f555903349422e_mergeImage.png);
  width: 920px;
  height: 47px;
  margin: 30px 0 0 500px;
}
.image-text_28 {
  width: 74px;
  height: 22px;
  margin: 12px 0 0 24px;
}
.thumbnail_14 {
  width: 16px;
  height: 16px;
  margin-top: 3px;
}
.text-group_25 {
  width: 48px;
  height: 22px;
  overflow-wrap: break-word;
  color: #78208e;
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;
}
.section_9 {
  width: 281px;
  height: 47px;
  background: url(./img/SketchPng7547143a14156db94ba5a42634ea6b3b112de9a6413a0bb23e9defa32444e262.png) 100% no-repeat;
  background-size: 100% 100%;
  margin: 0 159px 0 382px;
}
.box_26 {
  width: 610px;
  height: 123px;
  margin: 10px 0 0 500px;
}
.box_27 {
  background-color: #f7f7f7;
  width: 300px;
  height: 123px;
}
.block_9 {
  width: 270px;
  height: 28px;
  margin: 20px 0 0 10px;
}
.image-text_29 {
  width: 88px;
  height: 28px;
}
.label_19 {
  width: 28px;
  height: 28px;
}
.text-group_26 {
  width: 48px;
  height: 22px;
  overflow-wrap: break-word;
  color: #2f2f2f;
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;
  margin-top: 3px;
}
.text-wrapper_14 {
  border-radius: 2px;
  height: 15px;
  border: 1px solid #fe5100;
  width: 28px;
  margin: 8px 0 0 10px;
}
.text_50 {
  width: 20px;
  height: 14px;
  overflow-wrap: break-word;
  color: #fe5100;
  font-size: 10px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  line-height: 14px;
  margin-left: 4px;
}
.thumbnail_15 {
  width: 16px;
  height: 16px;
  margin: 6px 0 0 128px;
}
.text_51 {
  width: 219px;
  height: 48px;
  overflow-wrap: break-word;
  color: #464646;
  font-size: 13px;
  font-family: PingFangSC-Regular;
  font-weight: NaN;
  text-align: left;
  line-height: 24px;
  margin: 4px 0 23px 50px;
}
.box_28 {
  background-color: #f7f7f7;
  width: 300px;
  height: 123px;
}
.section_10 {
  width: 270px;
  height: 28px;
  margin: 20px 0 0 10px;
}
.image-text_30 {
  width: 96px;
  height: 28px;
}
.label_20 {
  width: 28px;
  height: 28px;
}
.text-group_27 {
  width: 56px;
  height: 22px;
  overflow-wrap: break-word;
  color: #2f2f2f;
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;
  margin-top: 3px;
}
.text-wrapper_15 {
  border-radius: 2px;
  height: 15px;
  border: 1px solid #fe5100;
  width: 28px;
  margin: 7px 0 0 10px;
}
.text_52 {
  width: 20px;
  height: 14px;
  overflow-wrap: break-word;
  color: #fe5100;
  font-size: 10px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  line-height: 14px;
  margin-left: 4px;
}
.thumbnail_16 {
  width: 16px;
  height: 16px;
  margin: 6px 0 0 120px;
}
.text_53 {
  width: 219px;
  height: 48px;
  overflow-wrap: break-word;
  color: #464646;
  font-size: 13px;
  font-family: PingFangSC-Regular;
  font-weight: NaN;
  text-align: left;
  line-height: 24px;
  margin: 4px 0 23px 50px;
}
.box_29 {
  background-image: url(./img/c8b23fdff0a04d8099775b75099f01a7_mergeImage.png);
  width: 920px;
  height: 47px;
  margin: 30px 0 0 500px;
}
.image-text_31 {
  width: 74px;
  height: 22px;
  margin: 12px 0 0 24px;
}
.thumbnail_17 {
  width: 16px;
  height: 16px;
  margin-top: 3px;
}
.text-group_28 {
  width: 48px;
  height: 22px;
  overflow-wrap: break-word;
  color: #78208e;
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;
}
.block_10 {
  width: 281px;
  height: 47px;
  background: url(./img/SketchPng7547143a14156db94ba5a42634ea6b3b112de9a6413a0bb23e9defa32444e262.png) 100% no-repeat;
  background-size: 100% 100%;
  margin: 0 136px 0 405px;
}
.box_30 {
  width: 610px;
  height: 123px;
  margin: 10px 0 0 500px;
}
.block_11 {
  background-color: #f7f7f7;
  width: 300px;
  height: 123px;
}
.box_31 {
  width: 270px;
  height: 28px;
  margin: 20px 0 0 10px;
}
.image-text_32 {
  width: 104px;
  height: 28px;
}
.label_21 {
  width: 28px;
  height: 28px;
}
.text-group_29 {
  width: 64px;
  height: 22px;
  overflow-wrap: break-word;
  color: #2f2f2f;
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;
  margin-top: 3px;
}
.text-wrapper_16 {
  border-radius: 2px;
  height: 15px;
  border: 1px solid #fe5100;
  width: 28px;
  margin: 8px 0 0 10px;
}
.text_54 {
  width: 20px;
  height: 14px;
  overflow-wrap: break-word;
  color: #fe5100;
  font-size: 10px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  line-height: 14px;
  margin-left: 4px;
}
.thumbnail_18 {
  width: 16px;
  height: 16px;
  margin: 6px 0 0 112px;
}
.text_55 {
  width: 219px;
  height: 48px;
  overflow-wrap: break-word;
  color: #464646;
  font-size: 13px;
  font-family: PingFangSC-Regular;
  font-weight: NaN;
  text-align: left;
  line-height: 24px;
  margin: 4px 0 23px 50px;
}
.block_12 {
  background-color: #f7f7f7;
  height: 123px;
  width: 300px;
}
.group_18 {
  width: 270px;
  height: 28px;
  margin: 20px 0 0 10px;
}
.image-text_33 {
  width: 72px;
  height: 28px;
}
.label_22 {
  width: 28px;
  height: 28px;
}
.text-group_30 {
  width: 32px;
  height: 22px;
  overflow-wrap: break-word;
  color: #2f2f2f;
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;
  margin-top: 3px;
}
.text-wrapper_17 {
  border-radius: 2px;
  height: 15px;
  border: 1px solid #fe5100;
  width: 28px;
  margin: 8px 0 0 10px;
}
.text_56 {
  width: 20px;
  height: 14px;
  overflow-wrap: break-word;
  color: #fe5100;
  font-size: 10px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  line-height: 14px;
  margin-left: 4px;
}
.thumbnail_19 {
  width: 16px;
  height: 16px;
  margin: 6px 0 0 144px;
}
.text-wrapper_18 {
  width: 195px;
  height: 24px;
  margin: 4px 0 47px 50px;
}
.text_57 {
  width: 195px;
  height: 24px;
  overflow-wrap: break-word;
  color: #464646;
  font-size: 13px;
  font-family: PingFangSC-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 24px;
}
.box_32 {
  background-image: url(./img/b25a21399e484845801d8953f3a16edd_mergeImage.png);
  width: 920px;
  height: 47px;
  margin: 30px 0 0 500px;
}
.image-text_34 {
  width: 58px;
  height: 22px;
  margin: 12px 0 0 24px;
}
.thumbnail_20 {
  width: 16px;
  height: 16px;
  margin-top: 3px;
}
.text-group_31 {
  width: 32px;
  height: 22px;
  overflow-wrap: break-word;
  color: #78208e;
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;
}
.group_19 {
  width: 281px;
  height: 47px;
  background: url(./img/SketchPng7547143a14156db94ba5a42634ea6b3b112de9a6413a0bb23e9defa32444e262.png) 100% no-repeat;
  background-size: 100% 100%;
  margin: 0 136px 0 421px;
}
.box_33 {
  width: 920px;
  height: 123px;
  margin: 10px 0 0 500px;
}
.block_13 {
  background-color: #f7f7f7;
  width: 300px;
  height: 123px;
}
.section_11 {
  width: 270px;
  height: 28px;
  margin: 20px 0 0 10px;
}
.image-text_35 {
  width: 104px;
  height: 28px;
}
.label_23 {
  width: 28px;
  height: 28px;
}
.text-group_32 {
  width: 64px;
  height: 22px;
  overflow-wrap: break-word;
  color: #2f2f2f;
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;
  margin-top: 3px;
}
.text-wrapper_19 {
  border-radius: 2px;
  height: 15px;
  border: 1px solid #fe5100;
  width: 28px;
  margin: 8px 0 0 10px;
}
.text_58 {
  width: 20px;
  height: 14px;
  overflow-wrap: break-word;
  color: #fe5100;
  font-size: 10px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  line-height: 14px;
  margin-left: 4px;
}
.thumbnail_21 {
  width: 16px;
  height: 16px;
  margin: 6px 0 0 112px;
}
.paragraph_7 {
  width: 219px;
  height: 53px;
  overflow-wrap: break-word;
  color: #464646;
  font-size: 13px;
  font-family: PingFangSC-Regular;
  font-weight: NaN;
  text-align: left;
  line-height: 24px;
  margin: 4px 0 18px 50px;
}
.block_14 {
  background-color: #f7f7f7;
  width: 300px;
  height: 123px;
  margin-left: 10px;
}
.image-text_36 {
  width: 259px;
  height: 80px;
  margin: 20px 0 0 10px;
}
.label_24 {
  width: 28px;
  height: 28px;
}
.text-group_33 {
  width: 219px;
  height: 77px;
  margin-top: 3px;
}
.text_59 {
  width: 64px;
  height: 22px;
  overflow-wrap: break-word;
  color: #2f2f2f;
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;
}
.text_60 {
  width: 219px;
  height: 48px;
  overflow-wrap: break-word;
  color: #464646;
  font-size: 13px;
  font-family: PingFangSC-Regular;
  font-weight: NaN;
  text-align: left;
  line-height: 24px;
  margin-top: 7px;
}
.block_15 {
  background-color: #f7f7f7;
  width: 300px;
  height: 123px;
  margin-left: 10px;
}
.image-text_37 {
  width: 157px;
  height: 56px;
  margin: 20px 0 0 10px;
}
.label_25 {
  width: 28px;
  height: 28px;
}
.text-group_34 {
  width: 117px;
  height: 53px;
  margin-top: 3px;
}
.text_61 {
  width: 64px;
  height: 22px;
  overflow-wrap: break-word;
  color: #2f2f2f;
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;
}
.text_62 {
  width: 117px;
  height: 24px;
  overflow-wrap: break-word;
  color: #464646;
  font-size: 13px;
  font-family: PingFangSC-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 24px;
  margin-top: 7px;
}
.box_34 {
  background-image: url(./img/65452fbb1511417f920c86155545434c_mergeImage.png);
  width: 920px;
  height: 47px;
  margin: 29px 0 0 500px;
}
.image-text_38 {
  width: 58px;
  height: 22px;
  margin: 12px 0 0 24px;
}
.thumbnail_22 {
  width: 16px;
  height: 16px;
  margin-top: 3px;
}
.text-group_35 {
  width: 32px;
  height: 22px;
  overflow-wrap: break-word;
  color: #78208e;
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;
}
.box_35 {
  width: 281px;
  height: 47px;
  background: url(./img/SketchPng7547143a14156db94ba5a42634ea6b3b112de9a6413a0bb23e9defa32444e262.png) 100% no-repeat;
  background-size: 100% 100%;
  margin: 0 141px 0 416px;
}
.box_36 {
  width: 610px;
  height: 123px;
  margin: 10px 0 0 500px;
}
.box_37 {
  background-color: #f7f7f7;
  height: 123px;
  width: 300px;
}
.box_38 {
  width: 270px;
  height: 28px;
  margin: 20px 0 0 10px;
}
.image-text_39 {
  width: 226px;
  height: 28px;
}
.label_26 {
  width: 28px;
  height: 28px;
}
.text-group_36 {
  width: 186px;
  height: 22px;
  overflow-wrap: break-word;
  color: #2f2f2f;
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;
  margin-top: 3px;
}
.thumbnail_23 {
  width: 16px;
  height: 16px;
  margin-top: 6px;
}
.box_39 {
  width: 28px;
  height: 15px;
  margin-left: 50px;
}
.text-wrapper_20 {
  border-radius: 2px;
  height: 15px;
  border: 1px solid #fe5100;
  width: 28px;
}
.text_63 {
  width: 20px;
  height: 14px;
  overflow-wrap: break-word;
  color: #fe5100;
  font-size: 10px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  line-height: 14px;
  margin-left: 4px;
}
.text-wrapper_21 {
  width: 52px;
  height: 24px;
  margin: 12px 0 24px 50px;
}
.text_64 {
  width: 52px;
  height: 24px;
  overflow-wrap: break-word;
  color: #464646;
  font-size: 13px;
  font-family: PingFangSC-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 24px;
}
.box_40 {
  background-color: #f7f7f7;
  width: 300px;
  height: 123px;
}
.image-text_40 {
  width: 157px;
  height: 60px;
  margin: 20px 0 0 10px;
}
.label_27 {
  width: 28px;
  height: 28px;
}
.text-group_37 {
  width: 117px;
  height: 57px;
  margin-top: 3px;
}
.text_65 {
  width: 32px;
  height: 22px;
  overflow-wrap: break-word;
  color: #2f2f2f;
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;
}
.text_66 {
  width: 117px;
  height: 24px;
  overflow-wrap: break-word;
  color: #464646;
  font-size: 13px;
  font-family: PingFangSC-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 24px;
  margin-top: 11px;
}
.box_41 {
  background-color: #78208e;
  position: relative;
  width: 1920px;
  height: 177px;
  margin-top: 186px;
}
.paragraph_8 {
  width: 480px;
  height: 56px;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 20px;
  font-family: PingFangSC-Semibold;
  font-weight: 600;
  text-align: left;
  line-height: 28px;
  margin: 35px 0 0 520px;
}
.image-text_41 {
  width: 282px;
  height: 24px;
  margin: 12px 0 50px 520px;
}
.group_20 {
  position: relative;
  width: 94px;
  height: 24px;
  border: 1px solid #ffffff;
}
.image-text_42 {
  width: 74px;
  height: 18px;
  margin: 3px 0 0 10px;
}
.text-group_38 {
  width: 52px;
  height: 18px;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 13px;
  font-family: PingFangSC-Regular;
  font-weight: NaN;
  text-align: center;
  white-space: nowrap;
  line-height: 18px;
}
.thumbnail_24 {
  width: 16px;
  height: 16px;
  margin-top: 1px;
}
.image-text_43 {
  position: absolute;
  left: 10px;
  top: 3px;
  width: 74px;
  height: 18px;
}
.text-group_39 {
  width: 174px;
  height: 18px;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 24px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: justify;
  white-space: nowrap;
  line-height: 18px;
}
.image_5 {
  position: absolute;
  left: 1200px;
  top: -104px;
  width: 222px;
  height: 260px;
}
.box_42 {
  background-color: #505558;
  height: 319px;
  margin-bottom: 1px;
  width: 1920px;
}
.section_12 {
  width: 896px;
  height: 132px;
  margin: 38px 0 0 520px;
}
.section_13 {
  width: 78px;
  height: 120px;
  margin-top: 12px;
}
.text_67 {
  width: 78px;
  height: 22px;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;
}
.text-group_40 {
  width: 48px;
  height: 78px;
  margin-top: 20px;
}
.text_68 {
  width: 48px;
  height: 18px;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 13px;
  font-family: PingFangSC-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 18px;
}
.text_69 {
  width: 48px;
  height: 18px;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 13px;
  font-family: PingFangSC-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 18px;
  margin-top: 12px;
}
.text_70 {
  width: 48px;
  height: 18px;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 13px;
  font-family: PingFangSC-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 18px;
  margin-top: 12px;
}
.section_14 {
  width: 78px;
  height: 120px;
  margin: 12px 0 0 72px;
}
.text_71 {
  width: 64px;
  height: 22px;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  line-height: 22px;
}
.text-group_41 {
  width: 78px;
  height: 78px;
  margin-top: 20px;
}
.text_72 {
  width: 54px;
  height: 18px;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 13px;
  font-family: PingFangSC-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 18px;
}
.text_73 {
  width: 67px;
  height: 18px;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 13px;
  font-family: PingFangSC-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 18px;
  margin-top: 12px;
}
.text_74 {
  width: 78px;
  height: 18px;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 13px;
  font-family: PingFangSC-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 18px;
  margin-top: 12px;
}
.section_15 {
  width: 64px;
  height: 120px;
  margin: 12px 0 0 71px;
}
.text_75 {
  width: 64px;
  height: 22px;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  line-height: 22px;
}
.text-group_42 {
  width: 52px;
  height: 78px;
  margin-top: 20px;
}
.text_76 {
  width: 52px;
  height: 18px;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 13px;
  font-family: PingFangSC-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 18px;
}
.text_77 {
  width: 52px;
  height: 18px;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 13px;
  font-family: PingFangSC-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 18px;
  margin-top: 12px;
}
.text_78 {
  width: 52px;
  height: 18px;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 13px;
  font-family: PingFangSC-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 18px;
  margin-top: 12px;
}
.section_16 {
  width: 276px;
  height: 131px;
  margin-left: 257px;
}
.image-text_44 {
  width: 156px;
  height: 57px;
}
.image_6 {
  width: 95px;
  height: 32px;
  margin-left: 2px;
}
.text-group_43 {
  width: 156px;
  height: 18px;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 13px;
  font-family: PingFangSC-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 18px;
  margin-top: 7px;
}
.paragraph_9 {
  width: 276px;
  height: 54px;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 13px;
  font-family: PingFangSC-Regular;
  font-weight: NaN;
  text-align: left;
  line-height: 18px;
  margin-top: 20px;
}
.section_17 {
  width: 138px;
  height: 83px;
  margin: 30px 0 36px 1140px;
}
.image-text_45 {
  width: 64px;
  height: 83px;
}
.box_43 {
  background-color: rgba(216, 216, 216, 0.2);
  width: 64px;
  height: 64px;
}
.text-group_44 {
  width: 60px;
  height: 17px;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 12px;
  font-family: PingFangSC-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 17px;
  margin: 2px 0 0 2px;
}
.image-text_46 {
  width: 64px;
  height: 83px;
}
.group_21 {
  background-color: rgba(216, 216, 216, 0.2);
  width: 64px;
  height: 64px;
}
.text-group_45 {
  width: 60px;
  height: 17px;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 12px;
  font-family: PingFangSC-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 17px;
  margin: 2px 0 0 1px;
}
.box_44 {
  background-color: #414445;
  height: 96px;
  margin-top: -1px;
  width: 1920px;
}
.text-wrapper_22 {
  width: 234px;
  height: 36px;
  overflow-wrap: break-word;
  font-size: 0;
  font-family: PingFangSC-Regular;
  font-weight: NaN;
  text-align: right;
  line-height: 18px;
  margin: 30px 0 0 843px;
}
.paragraph_10 {
  width: 234px;
  height: 36px;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 13px;
  font-family: PingFangSC-Regular;
  font-weight: NaN;
  text-align: left;
  line-height: 18px;
}
.text_79 {
  width: 234px;
  height: 36px;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 13px;
  font-family: Helvetica;
  font-weight: NaN;
  text-align: left;
  line-height: 18px;
}
.text_80 {
  width: 234px;
  height: 36px;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 13px;
  font-family: PingFangSC-Regular;
  font-weight: NaN;
  text-align: left;
  line-height: 18px;
}
