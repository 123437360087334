body * {
  box-sizing: border-box;
  flex-shrink: 0;
}
body {
  font-family: PingFangSC-Regular, Roboto, Helvetica Neue, Helvetica, Tahoma, Arial, PingFang SC-Light, Microsoft YaHei;
  margin: 0px;
  padding: 0px;
  min-width: 950px;
}
::-webkit-scrollbar {
  position: relative;
  width: 7px;
  height: 7px;
  border-radius: 5px;
  background-color: #cecece;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(149, 149, 149);
  border-radius: 5px;
  height: 7px;
  width: 7px;
  background-clip: padding-box;
  min-height: 28px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(67, 68, 69);
}
button {
  margin: 0;
  padding: 0;
  border: 1px solid transparent;
  outline: none;
  background-color: transparent;
}
button:active {
  opacity: 0.6;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.justify-start {
  display: flex;
  justify-content: flex-start;
}
.justify-center {
  display: flex;
  justify-content: center;
}
.justify-end {
  display: flex;
  justify-content: flex-end;
}
.justify-evenly {
  display: flex;
  justify-content: space-evenly;
}
.justify-around {
  display: flex;
  justify-content: space-around;
}
.justify-between {
  display: flex;
  justify-content: space-between;
}
.align-start {
  display: flex;
  align-items: flex-start;
}
.align-center {
  display: flex;
  align-items: center;
}
.align-end {
  display: flex;
  align-items: flex-end;
}
.page-main {
  margin: 0px;
  padding: 0px;
  min-width: 950px;
  width: 100%;
}
.app-header {
  background-color: rgba(255, 255, 255, 0.9);
  width: 100%;
  height: 70px;
  top: 0px;
  left: 0px;
  display: flex;
}
.app-log-img {
  width: 118px;
  height: 40px;
  margin: 15px 0 0 60px;
}
.app-nav-container {
  height: 70px;
  margin-left: 300px;
  overflow: hidden;
}
.app-nav-container ul {
  display: flex;
  margin: 0px;
  padding: 0px;
  list-style: none;
}
.app-nav-container ul li {
  list-style: none;
  height: 70px;
  line-height: 70px;
  padding: 0px 15px;
  overflow-wrap: break-word;
  color: #464646;
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: justify;
  white-space: nowrap;
}
.app-nav-container ul li span {
  cursor: pointer;
}
.app-nav-container ul li[data-isactive="true"] {
  color: #78208e;
}
.app-nav-container ul li a {
  color: #464646;
}
.app-nav-container ul li[data-isactive="true"] a {
  color: #78208e;
}
.app-nav-container ul li:hover a {
  color: #78208e;
}
.app-nav-container a {
  text-decoration: none;
  cursor: pointer;
}
.app-nav-container a[data-isactive="true"] {
  color: #78208e;
}
.app-footer {
  margin: 0px;
  padding: 0px;
  min-width: 950px;
  width: 100%;
}
.app-footer-contact {
  background-color: #78208e;
  height: 177px;
  width: 100%;
}
.app-footer-contact-title {
  width: 580px;
  height: 56px;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 16px;
  font-family: PingFangSC-Semibold;
  font-weight: 600;
  text-align: left;
  line-height: 28px;
  margin-left: 350px;
}
.app-footer-contact-zixun {
  color: #ffffff;
  margin-left: 350px;
  margin-top: 63px;
}
.app-footer-contact-zixun-span {
  display: inline-block;
  width: 94px;
  height: 24px;
  line-height: 24px;
  border: 1px solid #ffffff;
  text-align: center;
  margin-right: 20px;
}
.app-footer-contact-zixun img {
  width: 13px;
  height: 13px;
  margin-left: 5px;
}
.assets_text-group_15__1Bkaf {
  border: none;
  width: 174px;
  height: 18px;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: justify;
  white-space: nowrap;
  line-height: 18px;
}
.app-footer-contact-main-img {
  position: relative;
  left: 1100px;
  top: -210px;
  width: 222px;
  height: 260px;
}
.app-footer-middle {
  width: 100%;
  height: 179px;
  background: #505558;
  display: flex;
  flex-direction: row;
}
.app-footer-middle-title {
  width: 160px;
  color: #ffffff;
  padding-top: 30px;
}
.app-footer-middle-title span {
  display: block;
  padding: 5px;
  text-align: left;
}
.app-footer-middle-title span[datatype="title"] {
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
}
.app-footer-middle-logo {
  color: #ffffff;
  margin-left: 100px;
  margin-top: 35px;
}
.app-footer-end {
  width: 100%;
  height: 54px;
  background: #414445;
  display: flex;
  text-align: center;
  color: #ffffff;
  flex-direction: column;
  justify-content: center;
}
.app-flex-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.app-flex {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
}
