.app-business-banner {
  width: 100%;
  height: 200px;
  background-image: url(./img/business-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  color: #ffffff;
  font-size: 34px;
  font-weight: 600;
}
.app-business-banner-t1 {
  padding-top: 30px;
  text-align: center;
  width: 100%;
}
.app-business-banner-t2 {
  text-align: center;
  width: 100%;
}
.app-business-nav {
  height: 51px;
  border-bottom: 1px solid #ded8d8;
  color: #2f2f2f;
  font-size: 16px;
  font-family: PingFangSC-Semibold;
  font-weight: 600;
}
.app-business-title {
  color: #2f2f2f;
  font-size: 34px;
  font-family: PingFangSC-Semibold;
  font-weight: 600;
  text-align: center;
}
.app-business-item {
  height: 50px;
  width: 300px;
  text-align: center;
  line-height: 50px;
  margin-bottom: 10px;
  background-color: #e8e6e6;
}
.app-business-item[data-right='true'] {
  margin-right: 10px;
}
.app-business-item[data-type='blank'] {
  background-color: #ffffff;
}
.app-business-item-bg {
  width: 920px;
  height: 47px;
  margin-bottom: 20px;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: left;
  line-height: 47px;
  padding-left: 10px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #78208E;
  margin-top: 10px;
}
.app-business-item-bg[data-type='ito'] {
  background-image: url(./img/business-ito.png);
}
.app-business-item-bg[data-type='xmwb'] {
  background-image: url(./img/business-xmwb.png);
}
.app-business-item-bg[data-type='yffw'] {
  background-image: url(./img/business-yffw.png);
}
