.app-solution-banner {
  width: 100%;
  height: 200px;
  background-image: url(./img/solution-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  color: #ffffff;
  font-size: 34px;
  font-weight: 600;
}
.app-solution-banner-t1 {
  padding-top: 30px;
  text-align: center;
  width: 100%;
}
.app-solution-banner-t2 {
  text-align: center;
  width: 100%;
}
.app-solution-title {
  color: #2f2f2f;
  font-size: 34px;
  font-family: PingFangSC-Semibold;
  font-weight: 600;
  text-align: center;
}
.app-solution-hot-title {
  width: 920px;
  text-align: left;
  color: #2f2f2f;
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  margin-top: 30px;
  margin-bottom: 30px;
}
.app-solution-hot-title span {
  padding: 0px 5px;
  color: #78208e;
}
.app-solution-hot-item {
  width: 300px;
  height: 254px;
}
.app-solution-hot-item[data-right='true'] {
  margin-right: 10px;
}
.app-solution-hot-item-up {
  width: 300px;
  height: 191px;
  background-repeat: no-repeat;
  background-size: cover;
}
.app-solution-hot-item-up[bg='1'] {
  background-image: url(./img/hot1.png);
}
.app-solution-hot-item-up[bg='2'] {
  background-image: url(./img/hot2.png);
}
.app-solution-hot-item-up[bg='3'] {
  background-image: url(./img/hot3.png);
}
.app-solution-hot-item-up-content {
  background-color: #78208e;
  width: 300px;
  height: 36px;
  line-height: 36px;
  position: relative;
  top: 155px;
  left: 0px;
  text-align: left;
}
.app-solution-hot-item-up-content span[data-type='name'] {
  line-height: 36px;
  height: 36px;
  padding: 0px 10px;
  color: #ffffff;
}
.app-solution-hot-item-down {
  width: 300px;
  height: 63px;
  line-height: 24px;
  text-align: left;
  padding: 10px 20px;
  color: #464646;
  font-size: 13px;
  background: #F7F7F7;
  font-family: PingFangSC-Regular;
}
.app-solution-hot-tag {
  display: inline-block;
  color: #fe5100;
  font-size: 10px;
  border: 1px solid #fe5100;
  line-height: 20px;
  height: 20px;
}
.app-solution-title {
  color: #2f2f2f;
  font-size: 34px;
  font-family: PingFangSC-Semibold;
  font-weight: 600;
  text-align: center;
}
.app-solution-nav {
  width: 920px;
  height: 47px;
  text-align: left;
  line-height: 47px;
  color: #78208e;
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  background-repeat: no-repeat;
  background-size: cover;
}
.app-solution-nav[data-type='jr'] {
  background-image: url(./img/xf-bg-jr.png);
}
.app-solution-nav[data-type='hlw'] {
  background-image: url(./img/xf-bg-hlw.png);
}
.app-solution-nav[data-type='tx'] {
  background-image: url(./img/xf-bg-tx.png);
}
.app-solution-nav[data-type='zzy'] {
  background-image: url(./img/xf-bg-zzy.png);
}
.app-solution-nav[data-type='fwy'] {
  background-image: url(./img/xf-bg-fwy.png);
}
.app-solution-nav[data-type='zq'] {
  background-image: url(./img/xf-bg-zq.png);
}
.app-solution-nav[data-type='qt'] {
  background-image: url(./img/xf-bg-qt.png);
}
.app-solution-nav span {
  padding: 0px 5px 0px 20px;
}
.app-solution-item {
  width: 300px;
  height: 123px;
  background-color: #f7f7f7;
  margin-bottom: 10px;
  display: flex;
}
.app-solution-item[data-right='true'] {
  margin-right: 10px;
}
.app-solution-item[data-type='blank'] {
  background-color: #ffffff;
}
.app-solution-hot-item-img-div {
  width: 50px;
}
.app-solution-hot-item-img-div div {
  width: 28px;
  height: 28px;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 10px;
  margin-left: 10px;
}
.app-solution-hot-item-img-div div[data-type="jr_fjr"] {
  background-image: url(./img/jr-item-fjr.png);
}
.app-solution-hot-item-img-div div[data-type="jr_yh"] {
  background-image: url(./img/jr-item-yh.png);
}
.app-solution-hot-item-img-div div[data-type="jr_zj"] {
  background-image: url(./img/jr-item-zj.png);
}
.app-solution-hot-item-img-div div[data-type="jr_bx"] {
  background-image: url(./img/jr-item-bx.png);
}
.app-solution-hot-item-img-div div[data-type="jr_hj"] {
  background-image: url(./img/jr-item-hj.png);
}
.app-solution-hot-item-img-div div[data-type="hlw_xls"] {
  background-image: url(./img/hlw-item-xls.png);
}
.app-solution-hot-item-img-div div[data-type="hlw_zhyl"] {
  background-image: url(./img/hlw-item-zhyl.png);
}
.app-solution-hot-item-img-div div[data-type="hlw_zhny"] {
  background-image: url(./img/hlw-item-zhny.png);
}
.app-solution-hot-item-img-div div[data-type="hlw_zhcx"] {
  background-image: url(./img/hlw-item-zhcx.png);
}
.app-solution-hot-item-img-div div[data-type="hlw_zhgy"] {
  background-image: url(./img/hlw-item-zhgy.png);
}
.app-solution-hot-item-img-div div[data-type="hlw_zhjy"] {
  background-image: url(./img/hlw-item-zhjy.png);
}
.app-solution-hot-item-img-div div[data-type="hlw_hymh"] {
  background-image: url(./img/hlw-item-hymh.png);
}
.app-solution-hot-item-img-div div[data-type="hlw_ltbk"] {
  background-image: url(./img/hlw-item-ltbk.png);
}
.app-solution-hot-item-img-div div[data-type="hlw_zbsp"] {
  background-image: url(./img/hlw-item-zbsp.png);
}
.app-solution-hot-item-img-div div[data-type="tx_5g"] {
  background-image: url(./img/tx-item-5g.png);
}
.app-solution-hot-item-img-div div[data-type="zzy_qcy"] {
  background-image: url(./img/zzy-item-qc.png);
}
.app-solution-hot-item-img-div div[data-type="zzy_gy40"] {
  background-image: url(./img/zzy-item-gy4.png);
}
.app-solution-hot-item-img-div div[data-type="fwy_fwzx"] {
  background-image: url(./img/fwy-item-fwzx.png);
}
.app-solution-hot-item-img-div div[data-type="fwy_ds"] {
  background-image: url(./img/fwy-item-ds.png);
}
.app-solution-hot-item-img-div div[data-type="zq_zhcs"] {
  background-image: url(./img/zq-item-zhcs.png);
}
.app-solution-hot-item-img-div div[data-type="zq_zhaf"] {
  background-image: url(./img/zq-item-zhaf.png);
}
.app-solution-hot-item-img-div div[data-type="zq_zhzw"] {
  background-image: url(./img/zq-item-zhzw.png);
}
.app-solution-hot-item-img-div div[data-type="qt_ict"] {
  background-image: url(./img/qt-item-ict.png);
}
.app-solution-hot-item-img-div div[data-type="qt_ny"] {
  background-image: url(./img/qt-item-ny.png);
}
.app-solution-hot-item-title {
  margin-top: 10px;
}
.app-solution-hot-item-title-text {
  padding-right: 10px;
  display: inline-block;
  color: #2f2f2f;
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  line-height: 22px;
}
.app-solution-hot-item-text {
  width: 219px;
  line-height: 24px;
  text-align: left;
}
