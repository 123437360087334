.app-partner-banner {
  width: 100%;
  height: 200px;
  background-image: url(./img/partner-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.app-partner-banner-title {
  padding-top: 90px;
  text-align: center;
  width: 100%;
  color: #ffffff;
  font-size: 34px;
  font-weight: 600;
}
.app-partner-main {
  width: 100%;
}
